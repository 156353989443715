<template>
  <div :class="{ textPage: $route.params.textPageSlug !== 'contacts' }">
    <section class="mb" v-if="!isUiLocked" ref="contentZone">
      <div class="textContainer">
        <the-main-text
          :title="textPageData.translate.title"
          :descr="textPageData.translate.description"
        ></the-main-text>
      </div>
      <the-map v-if="mapAppirience"></the-map>
      <component
        v-for="(item, idx) in constructorList"
        :is="item.component"
        :key="idx"
        :propsData="item.content"
      >
      </component>
      <component
        v-for="(wItem, idx) in widgetList"
        :is="wItem.component"
        :key="`${idx}w`"
        :propsData="wItem.content"
      >
      </component>
    </section>
    <app-news v-if="false"></app-news>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppNews from "../components/app-news.vue";
import TheMainText from "../components/common/the-main-text.vue";
import TheMap from "../components/contacts/the-map.vue";
import appSimpleText from "../components/text-components/app-simple-text.vue";
import AppTextAndImage from "../components/text-components/app-text-and-image.vue";
import accordionMixin from "@/textAccordion";
import AppQuote from "../components/text-components/app-quote.vue";
import RequestForm from '../components/common/RequestForm.vue';
export default {
  components: {
    "simple-text": appSimpleText,
    "image-and-text": AppTextAndImage,
    AppNews,
    TheMainText,
    "location-map": TheMap,
    quotes: AppQuote,
    "form1":RequestForm
  },
  mixins: [accordionMixin],
  data() {
    return {
      textPageData: null,
      constructorList: null,
      widgetList: null
    };
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"])
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    mapAppirience() {
      return (
        this.$route.name == "TextPage" &&
        this.$route.params.textPageSlug == "contacts"
      );
    }
  },
  async created() {
    try {
    this.lockUi();
      const res = await this.axios.post("/api/page/get-by-slug", {
        lang: this.currentLang,
        slug: this.$route.params.textPageSlug
      });
      this.textPageData = res.data.data;
      this.constructorList = res.data.data.constructor;
      this.widgetList = res.data.data.widgets;
       document.title = res.data.data.translate.meta_title || res.data.data.translate.title || "Dream Travel"
      this.unlockUi();
    } catch (err) {
      // this.$router.push('/404')
        // debugger
        console.log(err.response.status);
      if (err.response.status == '404') {
        this.$router.push({
          name: "NotFound",
          params: {
            locale: this.$i18n.locale == "en" ? null : this.$i18n.locale
          }
        });
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
    }, 300);
  }
};
</script>

<style lang="sass" scoped>
.uiElements
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 50px
    .date
        font-weight: 400
        font-size: 12px
        line-height: 100%
        color: #777777
    ul
        display: flex
        li
            &:first-of-type
                margin-right: 20px !important
                a
                    width: 107px
            &:not(:last-of-type)
                margin-right: 10px
            a
                border: 1px solid $c-btn
                height: 41px
                min-width: 41px
                display: flex
                align-items: center
                justify-content: center
                color: $c-btn
.mb
    margin-bottom: $section-offset
    @media (max-width: 768px)
        margin-bottom: 100px
</style>
