<template>
  <section class="mapWrapper container">
    <div class="mapInfo">
      <ul>
        <li>
          <p class="title">{{ propsData.title1 }}</p>
          <p>{{ globalSetting.address }}</p>
        </li>
        <li>
          <p class="title">{{ propsData.title2 }}</p>
          <p v-for="(item, idx) in globalSetting.phones" :key="idx">
            {{ item.label }}
          </p>
        </li>
        <li>
          <p class="title">{{ propsData.title3 }}</p>
          <p v-for="(item, idx) in globalSetting.phones" :key="idx">
            {{ item.label }}
          </p>
        </li>
      </ul>
    </div>
      <GmapMap
        class="map"
        :center="coords"
        :zoom="17"
        style="height: 600px"
      />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        propsData: {
            type: [Array, Object],
        },
    },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["globalSetting", "getSize"]),
    coords() {
          const temp = {}
            temp.lat = +this.globalSetting.maps_coord_lat
            temp.lng = +this.globalSetting.maps_coord_lng
          return temp
      }
  },
};
</script>

<style lang="sass" scoped>
.mapWrapper
    position: relative
    height: 600px
    margin: $section-offset auto
    @media (max-width: 1024px)
        height: 512px
    @media (max-width: 768px)
        margin: 100px auto
    @media (max-width: 576px)
        height: auto
        display: flex
        flex-direction: column
.mapInfo
    position: absolute
    left: 0px
    top: 0px
    max-width: 450px
    width: 100%
    background-color: rgba(#000, .6)
    padding: 50px
    z-index: 2
    height: inherit
    @media (max-width: 1024px)
        padding: 46px
        height: inherit
        max-width: 358px
    @media (max-width: 768px)
        padding: 25px
    @media (max-width: 576px)
        position: static
        max-width: unset
        text-align: center
        background-color: rgba(#000, .8)
    .title
        font-weight: 500
        margin-bottom: 15px
        font-size: 25px
        line-height: 140%
        color: #FFFFFF
    @media (max-width: 768px)
        font-size: 16px
        line-height: 140%
    ul
        margin: 0px
    li
        &:not(:last-of-type)
            margin-bottom: 78px
            @media (max-width: 1024px)
                margin-bottom: 60px
            @media (max-width: 768px)
                margin-bottom: 51px
        p
            font-weight: 300
            font-size: 14px
            line-height: 140%
            color: #FFFFFF
            &:not(:last-of-type)
                margin-bottom: 10px
.map
    width: 100%
    height: 100%
    top: 0px
    left: 0px
    z-index: 1
    @media (max-width: 1024px)
        height: 512px !important
    @media (max-width: 576px)
        // position: static
        // height: auto !important

</style>
